import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';

import './Footer.css';

export const Footerv2 = () => {

  return (
    <div className='section-bg'>
      <Container>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <h2 className='footer-section-title'><a href='tel:+15176730053'>517.673.0053</a></h2>
            </Grid> */}
            <div className='spacer-xs'></div>
            <Grid item xs={12} md={3}>
              <Link to='/about' className='link'><h2>About Us</h2></Link>
            </Grid>
            <Grid item xs={12} md={3}>
              <Link to='/services' className='link'><h2>Services</h2></Link>
            </Grid>
            <Grid item xs={12} md={3}>
              <Link to='/employment' className='link'><h2>Employment</h2></Link>
            </Grid>
            <Grid item xs={12} md={3}>
              <Link to='/contact' className='link'><h2>Contact us</h2></Link>
            </Grid>
            <Grid item xs={12} md={3}>
              <a href='https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-5_0.pdf' target="_blank" rel="noreferrer" className='link font-12-px'>TREC Consumer Protection Notice</a>
            </Grid>
            <div className='spacer'></div>
            <Grid item xs={12} className='footer-text-container'>
              <small className='footer-text'>Copyright &copy; {new Date().getFullYear()} 4D Acquisition &amp; Consulting, LLC. All Rights Reserved.</small>
            </Grid>
        </Grid>
      </Container>
    </div>
  );
}
